<template>
    <main class="order order-flight">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="navsteps navstepts-flight">
                        <div class="navsteps__item">
                            <div class="number">1</div>
                            <div class="descr">Разрахунок</div>
                        </div>
                        <div class="navsteps__item">
                            <div class="number">2</div>
                            <div class="descr">Вибір СК</div>
                        </div>
                        <div class="navsteps__item active">
                            <div class="number">3</div>
                            <div class="descr">Оформлення</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="orders osago">
            <div class="container">
                <div class="row flex-lg-row-reverse">
                    <div class="col-lg-4">
                        <AutocivilPromo></AutocivilPromo>
                    </div>
                    <div class="col-lg-8">
                        <a href="javascript:void(0);" class="go-back" @click="$router.go(-1)">
                            Повернутися до оформлення
                        </a>
                        <form class="step-block">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <h3>
                                                <span>Дані страхувальника (Покупець)</span>
                                                <!--                                                    <i data-placement="bottom" data-toggle="tooltip" title="" data-original-title="Подсказка" class="icon question"></i>-->
                                            </h3>
                                        </div>
                                        <div class="col-lg-12 row passport-row">
                                            <div class="col-lg-6">
                                                <div class="info-block">
                                                    <b>Повне ім'я</b>
                                                    <p>{{ payer.name + ' ' + payer.surname}}</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="info-block">
                                                    <b>Дата народження</b>
                                                    <p>{{ payer.dateBirth}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 row passport-row">
                                            <div class="col-lg-6">
                                                <div class="info-block">
<!--                                                    <b>Серія закордонного паспорту</b>-->
<!--                                                    <p>{{ payer.passportSerial}}</p>-->
                                                    <b>{{ getPlaceholderSerial() }}</b>
                                                    <p>{{ payer.documentation.serial}}</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="info-block">
<!--                                                    <b>Номер закордонного паспорту</b>-->
<!--                                                    <p>{{ payer.passportNumber}}</p>-->
                                                    <b>{{ getPlaceholderNumber()}}</b>
                                                    <p>{{ payer.documentation.number}}</p>
                                                </div>
                                            </div>

                                            <div v-if="payer.documentation.dateOfIssue" class="col-lg-6">
                                                <div class="info-block">
                                                    <b>Дата видачі документа</b>
                                                    <p>{{ payer.documentation.dateOfIssue}}</p>
                                                </div>
                                            </div>

                                            <div v-if="payer.documentation.endDateOfIssue" class="col-lg-6">
                                                <div class="info-block">
                                                    <b>Закінчення дії документа</b>
                                                    <p>{{ payer.documentation.endDateOfIssue}}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-12 row passport-row">




                                            <div class="col-lg-6">
                                                <div class="info-block">
                                                    <b>ІПН</b>
                                                    <p>{{ payer.identificationCode}}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-12 row passport-row">
                                            <div class="col-lg-6">
                                                <div class="info-block">
                                                    <b>Email</b>
                                                    <p>{{ payer.mail}}</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="info-block">
                                                    <b>Номер телефону</b>
                                                    <p>{{ payer.phone}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 row passport-row">
                                            <div class="col-lg-6">
                                                <div class="info-block">
                                                    <b>Адреса</b>
                                                    <p>{{ (payer.city ? (payer.city.name_ua || payer.city.name) : '')  + ', '+ payer.street + ', '+ payer.house }}{{payer.apartment ? ', ' + payer.apartment : ''}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <hr/>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <h3 class="mt">
                                                <span>Дані учасників</span>
                                            </h3>
                                        </div>
                                        <div v-for="(tourist, index) in TouristsList"
                                             :key="`tourist-${parseInt(index)+1}`"
                                             :id="`tourist-${parseInt(index)+1}`"
                                             class="col-lg-12 row passport-row">

                                            <div class="col-lg-12 row passport-row">
                                                <div class="col-lg-6">
                                                    <div class="info-block">
                                                        <b>Повне ім'я</b>
                                                        <p>{{ tourist.surname + ' ' + tourist.name }}</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="info-block">
                                                        <b>Дата народження</b>
                                                        <p>{{ tourist.dateBirth }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 row passport-row">
                                                <div class="col-lg-6">
                                                    <div class="info-block">
<!--                                                        <b>Серія закордонного паспорту</b>-->
                                                        <b>{{getTouristPlaceholderSerial(tourist.documentType)}} </b>
                                                        <p>{{ tourist.passportSerial }}</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="info-block">
<!--                                                        <b>Номер закордонного паспорту</b>-->
                                                        <b>{{getTouristPlaceholderNumber(tourist.documentType)}} </b>
                                                        <p>{{ tourist.passportNumber }}</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-lg-12 row passport-row">
                                                <div class="col-lg-6">
                                                    <div class="info-block">
                                                        <b>Дата видачі документу</b>
                                                        <p>{{ tourist.passportDate }}</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="info-block">
                                                        <b>ІПН</b>
                                                        <p>{{ tourist.identificationCode }}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-lg-12 row passport-row">
                                            <div class="col-lg-3">
                                                <div class="info-block">
                                                    <b>Загальна вартість:</b>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="price">
                                                    <p>{{ order.fullPrice || order.price }} грн.</p>
                                                </div>
                                            </div>
                                            <use-bonus
                                                class="col-lg-6"
                                                @show-earnings="showEarnings = $event"
                                                @use-bonuses="useBonuses = $event"
                                            ></use-bonus>
                                        </div>

                                        <earnings class="col-12 passport-row" v-show="earnings && showEarnings"
                                                  :earnings="earnings"
                                                  :use-bonuses="useBonuses"
                                                  v-model="bonusSystemValue"
                                                  :data-is-load-earning="dataIsLoadEarning"
                                        ></earnings>

                                        <div class="col-lg-6 text-center position-relative">
                                            <button
                                                type="button"
                                                v-on:click="showDraft()"
                                                class="btn btn-dark">
                                                Переглянути чернетку
                                            </button>
                                        </div>
                                        <!--                                            <div v-if="!confirmContractStatus" class="col-lg-6 text-center position-relative">
                                                                                        <button
                                                                                            type="button"
                                                                                            @click="$router.go(-1)"
                                                                                            class="btn btn-warning">
                                                                                            Редагувати дані
                                                                                        </button>
                                                                                    </div>-->
                                        <div v-if="confirmContractStatus" class="col-lg-6 text-center position-relative">
                                            <button
                                                type="button"
                                                @click="showAllOrderPaymentWays()"
                                                class="btn btn-warning">
                                                Варіанти оплати
                                            </button>
                                        </div>

                                        <div class="col-12">
                                            <hr/>
                                        </div>
                                        <div v-if="!confirmContractStatus" class="col-12">
                                            <div class="form-check">
                                                <input
                                                    type="checkbox"
                                                    name="agreement"
                                                    id="agreement"
                                                    :class="[
                                                                  'form-check-input',
                                                                  { 'is-invalid': agreement == false },
                                                                ]"
                                                    v-model="agreement"
                                                />
                                                <label for="agreement" class="form-check-label"
                                                >Згоден з обробкою персональних даних. Дані будуть
                                                    передані в страхову компанію для формування поліса</label>
                                                <small v-if="agreement == false"> Угода з обробкою персональних даних є обов'язковою!</small
                                                >
                                            </div>
                                        </div>
                                      <otp-block
                                          :data-otp-flags="otpFlags"
                                          :data-confirm-contract-status=true
                                          @formSendOtp="formSendOtp"
                                          @otpCheck="otpCheck"
                                          @otpChenged="otpChenged"
                                      ></otp-block>
                                      <div v-if="otpFlags.otpStatus && !otpFlags.confirmOtpStatus" class="col-12 text-center position-relative">
                                        <button
                                            type="button"
                                            :disabled="!otpFlags.confirmOtpStatus"
                                            class="btn btn-dark-grey">Оформити</button>
                                      </div>
                                        <div v-else-if="! confirmContractStatus && ((otpFlags.confirmOtpStatus && otpFlags.otpStatus) || !otpFlags.otpStatus)" class="col-12 text-center position-relative">
                                            <button
                                                type="button"
                                                v-on:click="makeContract()"
                                                class="btn btn-blue">Оформити</button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <modalDocument></modalDocument>
        <animationModal></animationModal>
        <choice-otp-sending-direction ref="ChoiceOtpSendingDirection" @sendOtpApi="sendOtpApi"></choice-otp-sending-direction>
        <modalSuccess @showload="downloadContract()"></modalSuccess>
        <modal-tourism-success ref="modalTourismSuccess"></modal-tourism-success>
        <modal-get-invoice-order ref="modalGetInvoiceOrder"></modal-get-invoice-order>
        <modal-get-instant-loan ref="modalGetInstantLoan"></modal-get-instant-loan>
        <modal-get-mono-pay-parts ref="showModalGetMonoPayParts"></modal-get-mono-pay-parts>
        <modal-get-all-payment-ways
            ref="modalGetAllPaymentWays"
            @showModalGetInvoiceOrder="showModalGetInvoiceOrder"
            @showModalGetInstantLoan="showModalGetInstantLoan"
            @showModalGetMonoPayParts="showModalGetMonoPayParts"
            @showModalGetInvoiceLegal="showModalGetInvoiceLegal"
            :is-legal-type="isLegalType"
        >
        </modal-get-all-payment-ways>
        <fin-monitoring :visible.sync="showFinMonitoringModal" @confirm="confirmFinMonitoring"></fin-monitoring>
    </main>
</template>


<script>
import animationModal from "@/components/app/modals/modal-payAnimation";
import Multiselect from 'vue-multiselect';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import AutocivilPromo from '@/views/flight/components/AutocivilPromo.vue';
import modalDocument from '@/components/app/modals/modal-flight-document';
import modalSuccess from '@/components/app/modals/modal-green-card-success';
import {downloadInvoiceLegal, downloadOrder, downloadPdf, getPaymentInvoice, showOrder} from "@/api/order";
import {
  TourismSuccess as ModalTourismSuccess,
  GetAllPaymentWays as ModalGetAllPaymentWays,
  GetPaymentLink as ModalGetInvoiceOrder,
  GetInstantLoan as ModalGetInstantLoan,
  GetMonoPayParts as ModalGetMonoPayParts, ChoiceOtpSendingDirection,
} from "@/components/app/modals/order";
import { orderTourism } from "@/api/orderTourism";
import otpBlock from "@/components/app/otp/otpBlock.vue";
import {otpFunc} from "@/mixin";
import Earnings from "@/components/app/bonusSystem/Earnings.vue";
import axios from "axios";
import UseBonus from "@/components/app/bonusSystem/UseBonus.vue";
import FinMonitoring from "@/components/app/modals/order/FinMonitoring.vue";


export default {
    components: {
        FinMonitoring,
        ChoiceOtpSendingDirection,
        otpBlock,
        UseBonus,
        Earnings,
        Multiselect,
        VueTypeaheadBootstrap,
        AutocivilPromo,
        modalDocument,
        modalSuccess,
        animationModal,
        ModalTourismSuccess,
        ModalGetAllPaymentWays,
        ModalGetInvoiceOrder,
        ModalGetInstantLoan,
        ModalGetMonoPayParts,
    },
    mixins: [otpFunc],
    data: function () {
        return {
            isAgreeFinMonitoring:false,
            showFinMonitoringModal:false,
            otpFlags:{
              otpStatus: true, // true - Включить отправку ОТР - кода
              confirmOtpStatus: false, // true - OTP код проверен успешно
              sentOtpPass: false, // true - OTP код был отправлен mail/viber/sms
              sendingOtpError: false, // true - при отправке ОТР кода произошла ошибка
              submitOtpCheck: false, // true - была нажата кнопка отправки ОТР кода на проверку
              otpCodeValidate: true, // статус проверки ОТР кода true - прошел проверку, false - код неверный
              pandingOtpSending: false, // флаг для отображения прелоадера при отправке ОТР кода
              pandingOtpCheck: false, // флаг для отображения прелоадера при отправке ОТР кода на проверку
            },
            earnings: 0,
            useBonuses: false,
            showEarnings: false,
            bonusSystemValue: 0,
            agreement: null,
            enableEditform: false,
            dataIsLoadEarning: true,
            LetterMaskError: false,
            showPopularCountries:false,
            confirmContractStatus: false,
            isLegalType: false,
            offerInfo: null,
            contractId: null,
            orderId: null,
            carBrands:[],
            carModels:[],
            styleSel: {
                border: 'none',
            },
            searchInfo: null,
            showOtp: false,
            elSelectStatus:true,
            createTicketInfo: false,
            createUserInfo: false,
            submitCheck: false,
            personalCheck: false,
            cityFrom: null,
            payer: {},
            TouristsList: [],
            optionsStartDate: {
                format: 'DD.MM.YYYY',
                useCurrent: false,
                minDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
                locale: 'ru',
                icons: {
                    previous: 'icon arrow-point-to-left',
                    next: 'icon arrow-point-to-right',
                    time: "fa fa-clock-o",
                    date: "fa fa-calendar",
                },
            },
            order:{
                startDate: null,
                endDate: null,
                country: null,
                periodOption:null,
                info:{

                    identificationCode: null,
                    withoutIdentificationCode:false,
                    withoutDocuments:false,
                    name: null,
                    surname: null,
                    dateBirth: null,
                    phone: null,
                    mail: null,
                    city: null,
                    street: null,
                    house: null,
                    apartment: null,

                    //
                    documentType: null,
                    internationalPassport: null,
                    passportSerial: null,
                    passportNumber: null,
                    passportCompany: null,
                    passportDate: null,

                    documentation: {
                        //TODO текущее значение нобходимо будет брать с словаря(api)
                        type: 'DOCUMENT_EXTERNAL_PASSPORT',
                        serial: null,
                        number: null,
                        issuedBy: null,
                        dateOfIssue: null,
                        endDateOfIssue: null,
                        dateOfIssueError: false,
                    },
                }
            },
        }
    },

    created() {
        this.fetchOfferInfo(this.$store.getters.GET_TOURISM_INFO_OFFER);
        this.fetchSearchInfo(this.$store.getters.GET_SEARH_TOURISM_PARAMS);
        this.fetchTouristsList(this.$store.getters.GET_TOURISM_INFO_TOURISTSLIST);
        this.fetchOrder(this.$store.getters.GET_TOURISM_FULL_ORDER);
        this.fetchUserInfo(this.$store.getters.GET_TOURISM_INFO_USER);

    },

    computed: {
        cityStore() {
            return this.car.city
        },
        countryStore() {
            if(this.dataCountry){
                return this.dataCountry
            }
        },
        getParentUserId() {
            return this.$store.getters.USER_INFO.parentId ?? this.$store.getters.USER.id;
        }
    },

    methods: {
        showAllOrderPaymentWays() {
            const price = this.order.fullPrice ?? this.order.price;
            this.$refs.modalGetAllPaymentWays.open(this.order.orderId, price);
        },
        showModalGetInvoiceOrder() {
            const price = this.order.fullPrice ?? this.order.price;
            this.$refs.modalGetInvoiceOrder.open(this.order.orderId, price);
        },
        showModalGetInstantLoan() {
            this.$refs.modalGetInstantLoan.open(this.order.orderId, this.order.fullPrice ?? this.order.price);
        },
        showModalGetInvoiceLegal: async function() {
            const price = this.order.fullPrice ?? this.order.price;

            let orderWithData = await showOrder(this.order.orderId);
            const mtsbuCode = orderWithData.data.data?.mtsbuCode ?? null;
            let invoiceLegalData = {
                company: this.order.info?.name,
                code: this.order.info?.erdpyCode ?? '',
                price: price,
                mtsbuCode: mtsbuCode,
            }
            let response = await getPaymentInvoice(this.order.orderId, price, 'legal-entity-insurance');

            let params = new URLSearchParams(invoiceLegalData).toString()
            downloadInvoiceLegal(this.getParentUserId, this.order.orderId, params)
        },
        showModalGetMonoPayParts() {
            const price = this.order.fullPrice ?? this.order.price;
            this.$refs.showModalGetMonoPayParts.open(this.order.orderId, price, []);
        },
        confirmFinMonitoring() {
            setTimeout(() => {
                this.isAgreeFinMonitoring = true;
                this.makeContract('make');
            }, 1500);
        },
        checkFinMonitoring() {
            if (this.order.price >= 40000 && !this.isAgreeFinMonitoring) {
                this.showFinMonitoringModal = true;

                return true;
            }

            return false;
        },
        makeContract(type) {
            if (this.agreement) {
                if (this.checkFinMonitoring()) {
                    return;
                }
                this.showAnimateModal();

                let offerInfo = this.offerInfo;
                let searchInfo = this.searchInfo;
                let userInfo = this.payer;
                let TouristsListInfo = this.TouristsList;

                let params = {
                    type: type,
                    statusId: null,
                };

       /*         this.$store.commit('SET_TOURISM_INFO_USER', userInfo);
                this.$store.commit('SET_TOURISM_INFO_TOURISTSLIST', TouristsList);
*/

                orderTourism(offerInfo, searchInfo, userInfo, TouristsListInfo, this.order.orderId, params, this.bonusSystemValue).then((response) => {
                    $('#modalPayAnimation').modal('hide');
                    if (response && response.data.count >= 1) {
                        let fullInfo = Object.assign({}, this.fullInfo);
                        fullInfo.confirmContractStatus = true;
                        fullInfo.invoiceLink = response.data.data[0]?.mtsbuLink;
                        this.confirmContractStatus = true;
                        this.orderId = response.data.data[0]['order'].id;
                        this.$store.commit('SET_TOURISM_FULL_ORDER', response.data.data[0]['order'].id);

                        this.$store.commit('SET_TOURISM_CONTRACT_ID', response.data.data[0]['contract'].id);
                        this.contractId = response.data.data[0]['contract'].id;

                        if (response.data.data[0]['contract'].status == 'signed') {
                            this.$refs.modalTourismSuccess.open(response.data.data[0]['contract'].mtsbu_сode, this.contractId);
                        } else {
                            this.$alert("Поліс не оформлен", '', 'error');
                        }
                        return true;
                    }
                    this.$alert("Поліс не заявлений, перевірте будь ласка дані і спробуйте ще!", '', 'error', {width: '300px'});
                }).catch(error => {
                    $('#modalPayAnimation').modal('hide');
                  if (error?.response?.data?.data?.orderId[0].includes('недостатньо коштів')) {
                    this.$alert(error?.response?.data?.data?.orderId[0], 'Недостатньо коштів', 'error');
                  } else {
                    this.$alert("Поліс не оформлен", '', 'error');
                  }

                });
            }else {
                this.agreement = false;
            }
        },
        fetchOrder(data){
            if(data){
                this.order = data;
                this.orderId = this.order.orderId;
            }
        },
        fetchSearchInfo(data){
            if(data){
                this.searchInfo = data;
            }
        },
        fetchTouristsList(data){
            if(data){
                this.TouristsList = data;
            }
        },
        fetchOfferInfo(data){
            if(data){
                this.offerInfo = data;
            }
        },
        fetchUserInfo(data){
            if(data){
                this.payer = data;
            }
        },
        downloadContract() {
            $('#modalFlightSuccess').modal('hide');
            downloadOrder(this.$store.getters.USER.id, this.order.orderId);
        },
        showDraft() {

            this.showAnimateModal();
            let offerInfo = this.offerInfo;
            let searchInfo = this.searchInfo;
            let userInfo = this.payer;
            let TouristsListInfo = this.TouristsList;

            let params = {
                type: 'save',
                statusId: null,
            };

            orderTourism(offerInfo, searchInfo, userInfo, TouristsListInfo, this.order.orderId, params).then((response) => {

                $('#modalPayAnimation').modal('hide');

                if (response && response.data.count >= 1) {

                    const contractData = response.data.data[0];
                    //console.log(contractData);

                    if (contractData.contractDraft_path) {
                        window.open(contractData.contractDraft_path);
                    } else {
                        this.$alert('Спробуйте пізніше');
                    }
                    return true;

                }
                this.$alert("'Щось пішло не так, спробуйте ще раз'!", '', 'error', {width: '300px'});
            }).catch(error => {
                $('#modalPayAnimation').modal('hide');
                if (error?.response?.data?.data?.orderId[0].includes('недостатньо коштів')) {
                  this.$alert(error?.response?.data?.data?.orderId[0], 'Недостатньо коштів', 'error');
                } else {
                  this.$alert("Щось пішло не так.", '', 'error');
                }
            });
        },
        citySelect(data) {
            this.car.city = data.city;
        },
        countrySelect(data) {
            this.country = data.country;
        },
        showAnimateModal() {
            $("#modalPayAnimation").modal({
                backdrop: "static",
                keyboard: true,
                show: true,
            });

            let i = 0;

            if (i == 0) {
                i = 1;
                let width = 1;
                let id = setInterval(frame, 200);

                function frame() {
                    if (width >= 99) {
                        clearInterval(id);
                        i = 0;
                    } else {
                        width += 2;
                        $(".results_anime_percent-text").text(`${width}%`);
                        $(".results_anime_line-scale").css({width: `${width}%`});
                    }
                }
            }
        },
        checkPeriodOption(){
            if(!this.order.periodOption || this.order.periodOption.id == undefined){
                this.periodOptionStatus = false;
            }else{
                this.periodOptionStatus = true;
            }
        },
        isInvalid(val) {
            return val == null && this.submitForm;
        },
        getPlaceholderSerial() {
            if (this.payer.documentation.type == 3) {
                return 'Запис ID-паспорта';
            }

            return 'Серія закордонного паспорту';
        },
        getPlaceholderNumber() {
            if (this.payer.documentation.type == 3) {
                return 'Номер ID-паспорта';
            }

            return 'Номер закордонного паспорту';
        },
        getTouristPlaceholderSerial(touristDocument) {
            if (touristDocument == "DOCUMENT_ID_CARD") {
                return 'Запис ID-паспорта';
            }
            return 'Серія закордонного паспорту';
        },
        getTouristPlaceholderNumber(touristDocument) {
            if (touristDocument == "DOCUMENT_ID_CARD") {
                return 'Номер ID-паспорта';
            }
            return 'Номер закордонного паспорту';
        },
        calculateDate(startDate, period_option, yearProgram) {
            // Преобразуем строку в объект moment
            let startMoment = moment(startDate, 'DD.MM.YYYY');

            // Получаем сегодняшнюю дату
            const today = moment().startOf('day');

            // Если startMoment меньше или равен сегодняшней дате, установим startMoment на завтрашний день
            if (startMoment.isSameOrBefore(today)) {
                startMoment = startMoment.add(1, 'day');
            }

            // Установим endMoment на period_option дней после startMoment
            let endMoment = startMoment.clone().add(period_option, 'days');

            // Если установлен флаг yearProgram, увеличим endMoment на год
            if (yearProgram) {
                endMoment = startMoment.clone().add(1, 'year').subtract(1, 'day');
            }else{
                endMoment = endMoment.subtract(1, 'day');
            }

            // Возвращаем новые даты в формате 'дд.мм.гггг'
            return {
                startDate: startMoment.format('DD.MM.YYYY'),
                endDate: endMoment.format('DD.MM.YYYY')
            };
        },
        updateSelectedClients() {
            this.order.birthDates = [];
            this.order.humans.forEach(human => {
                human.value = 0;
                human.birthDates = [];
            });

            this.TouristsList.forEach(item => {
                const birthDate = moment(item.dateBirth, 'DD.MM.YYYY');
                const age = moment().diff(birthDate, 'years');
                this.order.humans.forEach(human => {
                    if (age >= human.min && age <= human.max) {
                        human.value++;
                        human.birthDates.push(moment(birthDate).format('DD.MM.YYYY'));
                        this.order.birthDates.push(moment(birthDate).format('DD.MM.YYYY'))
                    }
                });
            });
        },
        async recalculateEarnings() {
            this.dataIsLoadEarning = true;
            this.updateSelectedClients()
            let startDate = moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+1)).format('DD.MM.YYYY');

            let responseDates = this.calculateDate(startDate, this.order.period_option, this.order.yearProgram);

            let params = {
                date: responseDates.startDate,
                end: responseDates.endDate,
                days: parseInt(this.order.period_option),
                country: this.order.country,
                tourists: this.TouristsList.length,
                humans: this.order.humans,
                yearProgram: this.order.yearProgram,
                birthDates: this.order.birthDates,
                filters: this.order.filters,
                activities: this.order.filters.activities.value
            };

            this.$store.commit('SET_SEARCH_TOURISM_PARAMS', params);
            try {
                const {data: {data}}  = await axios.post('api/' + process.env.VUE_APP_SERVER_MODE + '/insurance/calculator/tourism', this.$store.getters.GET_SEARH_TOURISM_PARAMS);
                let offer = this.$_.find(data, (item) => {
                    return item.id === this.offerInfo.id;
                });
                this.order.fullPrice = offer.price;
                this.order.price = offer.price;
                this.earnings = offer.earnings;
                this.dataIsLoadEarning = false;
            } catch (error) {
                this.dataIsLoadEarning = false;
            }
        }
    },
    async mounted() {
        await this.recalculateEarnings()
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
    .osago {
        overflow-x: unset;
    }
</style>
